/* src/styles/Resume.css */

  @media screen and (max-width: 600px) {
    #resume {
      padding: 1em;
    }
  
    #resume p {
      font-size: 0.9em;
    }
  }